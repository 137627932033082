import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchPipeCustom } from './pipes/search.pipe';

@NgModule({
  declarations: [SearchPipeCustom],
  imports: [CommonModule],
  exports: [SearchPipeCustom],
})
export class SharedModule {}

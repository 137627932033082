import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchcustom',
})
export class SearchPipeCustom implements PipeTransform {
  transform(activities: any, term?: any): any {
    if (term === undefined) return activities;
    if (isNaN(term)) {
      if (term.includes('C') || term.includes('A') || term.includes('E')) {
        return activities.filter(function (activity: any) {
          return activity.status.toLowerCase().includes(term.toLowerCase());
        });
      } else {
        return activities.filter(function (activity: any) {
          return activity.nomeCliente
            .toLowerCase()
            .includes(term.toLowerCase());
        });
      }
    } else if (!isNaN(term)) {
      return activities.filter(function (activity: any) {
        return activity.telefoneCliente.includes(term);
      });
    }
  }
}
